import {createSetTitleCallbackApp, sendCallback} from "../../../assets/js/app-callbacks";
import {appendHtml} from "../../../assets/js/utils"

class BuchhandlungSeite extends HTMLElement {
    connectedCallback() {
        window.addEventListener("scroll", () => { 
            this.scrollRichtungSetzen(); 
        });
        window.addEventListener("login.change", () => {
            location.reload();
        });
        if (this.sindAppCallbacksAktiv) {
            this.appCallbackSetTitle(this, this.dataset.titel);
        }
        this.initRecruitingKampagne(this.querySelector('recruiting-kampagne'));
    }

    scrollRichtungSetzen() {
        this.querySelector(".navigation").setAttribute("scroll-direction", this.scrollRichtungErkennen);
    }

    get sindAppCallbacksAktiv() {
        return this.dataset.appCallbacksActive === 'true';
    }

    appCallbackSetTitle(parent, title) {
        sendCallback(createSetTitleCallbackApp('setTitle', title), parent);
    }

    initRecruitingKampagne(container) {
        if (!container) {
            return;
        }
        fetch('/cms/include/buchhandlung/aktion/recruiting-kampagne')
            .then(async response => {
                if (response.ok) {
                    const html = await response.text();
                    appendHtml(container, html);
                }
            })
            .catch(error => console.error(error));
    }

    get scrollRichtungErkennen() {
        if ((document.body.getBoundingClientRect()).top > this.scrollPosition) {
            this.richtung = "up";
        } else {
            this.richtung = "down";
        }
        this.scrollPosition = (document.body.getBoundingClientRect()).top;
        return this.richtung;
    }

}
  
if (!customElements.get("buchhandlung-seite")) {
    customElements.define("buchhandlung-seite", BuchhandlungSeite);
}
